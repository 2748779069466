html, body {
    background-color: white;
}

body,
button,
h1,
h2,
h3,
h4,
h5,
h6,
html,
li,
span,
div,
p,
input {
  font-family: 'Kanit',sans-serif;
}